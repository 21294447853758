import { VpnKey, Group } from "@material-ui/icons";

import Login from "views/auth/Login.js";
import Users from "views/admin/users";

var adminRoutes = [
    {
        path: "/users",
        name: "Users",
        icon: Group,
        iconColor: "Primary",
        component: Users,
        layout: "/app",
        visibility: true,
    },
    {
        divider: true,
        layout: "/app",
        visibility: true,
    },
];

var authRoutes = [
    {
        path: "/login",
        name: "Login",
        icon: VpnKey,
        iconColor: "Info",
        component: Login,
        layout: "/auth",
    },
];
export { adminRoutes, authRoutes };

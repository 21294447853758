export const ADMIN_LOGIN = `query ($email: String!, $password: String!) {
  admin_login(email: $email, password: $password) {
    message
    token
  }
}
`;

export const LIST_ALL_USERS = `query ($pageSize: Int, $pageNumber: Int) {
  list_all_users (page_size: $pageSize, page_number: $pageNumber) {
    message
    data {
      _id
      name
      email
      mobile
      user_name
      img
      is_creator
      wallet_id
      devnet_credits
      mainnet_credits
      is_org
    }
    hasMore
  }
}
`;


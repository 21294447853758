import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box } from "@material-ui/core";
// core components
import CustomHeader from "components/Headers/CustomHeader";

import componentStyles from "assets/theme/views/admin/tables.js";
import CustomTable from "components/CustomTable/CustomTable";
import { listAllUsers } from "store/actions";
import { resetAllUsers } from "store/actions";

const useStyles = makeStyles(componentStyles);

function Users() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { usersData, showViewMore } = useSelector(
        ({
            users: {
                users: { list, showViewMore },
            },
        }) => ({
            usersData: list,
            showViewMore,
        })
    );
    
    useEffect(() => {
        dispatch(resetAllUsers());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllUsers());
    }
    

    return (
        <>
            <CustomHeader />
            <Container
                maxWidth={false}
                component={Box}
                marginTop="-6rem"
                classes={{ root: classes.containerRoot }}
            >
                <br />
                <CustomTable
                    title="Users"
                    columns={[
                        // { title: "User ID", field: "_id" },
                        { title: "Name", field: "name" },
                        { title: "Email", field: "email" },
                        { title: "Username", field: "user_name" },
                        { title: "Wallet ID", field: "wallet_id" },
                        { title: "Devnet credits", field: "devnet_credits" },
                        { title: "Mainnet credits", field: "mainnet_credits" },
                    ]}
                    data={usersData}
                    showViewMoreButton={showViewMore}
                    viewMoreButtonClick={fetchMoreData}
                />
            </Container>
        </>
    );
}

export default Users;
import { TOGGLE_LOADING, LIST_USERS_DATA, RESET_USERS_LIST_DATA } from "../types";
import { post } from "services";
import { LIST_ALL_USERS} from "graphql";

export const listAllUsers = () => {
    return async (dispatch, getState) => {
        dispatch({ type: TOGGLE_LOADING, payload: true });


        const { users: { pageSize, pageNumber, list }} = getState().users;

        let apiResponse = await post("", {
            query: LIST_ALL_USERS,
            variables: {
                pageSize: pageSize,
                pageNumber: pageNumber,
            },

        });

        if (apiResponse.data.customStatus) {
            let {
                list_all_users: { data, hasMore },
            } = apiResponse.data.data;

            let updatedList = [...list, ...data];

            dispatch({
                type: LIST_USERS_DATA,
                payload: {
                    list: updatedList,
                    showViewMore: hasMore,
                    pageNumber: pageNumber + 1,
                },
            });
        }
        dispatch({ type: TOGGLE_LOADING, payload: false });
    };
};

export const resetAllUsers = () => {
    return async (dispatch) => {
        dispatch({ type: RESET_USERS_LIST_DATA });
    };
};

export const TOGGLE_LOADING = "TOGGLE_LOADING";
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
export const TOGGLE_MODAL = "TOGGLE_MODAL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const SHOW_SNACKBAR = "SHOW_SNACKBAR";
export const CLEAR_SNACKBAR = "CLEAR_SNACKBAR";

export const REDIRECT_TO = "REDIRECT_TO";

export const LIST_USERS_DATA = "LIST_USERS_DATA";
export const RESET_USERS_LIST_DATA = "RESET_USERS_LIST_DATA";

